<template>
  <div>
    <portal to="title-page">
      <h1>Usuarios</h1>
    </portal>
    <v-data-table v-if="items != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="items"
      item-key="id"
      :search="search"
      fixed-header
      class="elevation-1"
    >
      <!-- show-select -->
      <!-- :single-select="singleSelect" -->
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Buscar"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-btn small color="primary" @click="$router.push({path:'/backend/admin/users/create'})"><v-icon>mdi-plus-thick</v-icon> Usuario</v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
       <template v-slot:item.status="{ item }">
        <v-btn @click="changeStatus(item)" rounded  small :color="item.status == 'active' ? 'green' : 'red'" dark> <v-icon small>{{mdiPencil}}</v-icon> {{ item.status }}</v-btn >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon 
          class="mr-2"
          @click="$router.push({path:`/backend/admin/users/${item.id}/edit`})"
        >
          {{mdiPencil}}
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-if="selectedItem"
      v-model="dialogStatus.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Cambiar status del usuario {{selectedItem.name}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormStatus"
            lazy-validation
          >
            <v-select
              v-model="selectedItem.status"
              :items="['active', 'inactive']"
              :rules="[v => !!v || 'El status es requerido']"
              label="Seleccione el status"
              required
            ></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogStatus.show = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!validFormStatus"
            @click="updateStatus"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mdiDelete, mdiPencil, mdiImageMultiple, mdiClose } from '@mdi/js';
  import firebase from 'firebase/app';
  import 'firebase/auth';
  const axios = require('axios');

  import User from "@/models/User.js";
  export default {
    name: 'Users',
    data: function () {
      return {
        model:new User,
        mdiDelete:mdiDelete,
        mdiPencil:mdiPencil,
        mdiImageMultiple:mdiImageMultiple,
        mdiClose:mdiClose,
        ////////////////////////////
        selected:[],
        tableHeaders:[
          {
            text: 'Email',
            align: 'start',
            sortable: true,
            value: 'email',
          },
          {
            text: 'role',
            align: 'start',
            sortable: true,
            value: 'role',
          },
          {
            text: 'Nombre',
            align: 'start',
            sortable: false,
            value: 'name',
            filterable:false
          },
          {
            text: "Status",
            align: "start",
            sortable: true,
            value: "status",
          },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        items:null,
        search:'',
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        dialogStatus:{
          show:false
        },
        selectedItem : null,
        validFormStatus:true,
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    },
    methods:{
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      changeStatus(item) {
        this.selectedItem = {... item};
        this.dialogStatus.show = true;
      },
      updateStatus() {
        let loading = this.$loading.show();
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
          var config = {
            method: 'post',
            url: process.env.VUE_APP_API_URL+'/api/users/update',
            data: {uid:this.selectedItem.uid, status: this.selectedItem.status},
            headers:{
              'token':idToken
            }
          };
          axios(config)
          .then(async () => {
            this.model.update(this.selectedItem.id, {status:this.selectedItem.status}).then(() => {
              loading.hide();
              this.dialogStatus.show = false;
              this.snackbar.show = true;
              this.snackbar.color = 'green';
              this.snackbar.message = `El status se actualizo correctamente`;
            })
            .catch(error => {
              loading.hide();
              console.log(error);
              this.snackbar.show = true;
              this.snackbar.color = 'red';
              this.snackbar.message = `Error al actualizar el status, por favor contacte al Administrador si el problema persiste`;
            })
          })
          .catch((error) => {
            console.log(error);
            loading.hide()
            if (error.response && error.response.data && error.response.data.error) {
              this.showSnackbarError(error.response.data.error.message)
            } else {
              this.showSnackbarError('El usuario no se ha podido actualizar, intente de nuevo. Si el problema persiste contacte al Administrador')
            }
          });
        }).catch((error) => {
          // Handle error
          console.log('error al obtener el token', error);
          loading.hide()
          this.showSnackbarError('El usuario no se ha podido actualizar, intente de nuevo. Si el problema persiste contacte al Administrador')
        });
      }
    },
    mounted() {
      this.model.getAll().onSnapshot(snap => {
        this.items = [];
        snap.forEach(doc => {
          this.items.push(doc.data());
        })
      })
    }
  }
</script>